import React, { useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";


const Container = styled.div`
    display: flex;
    flex-direction: column;

    text-wrap: nowrap;
    border: 1px solid #676767;
    border-radius: 10px;
    padding: 16px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s ease;
    background-color: rgba( 24, 24, 28, 1 );

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${(props) => props.gradient};
        opacity: ${(props) => (props.isHovered ? 1 : 0)};
        transition: opacity 0.3s ease; /* Smooth transition for the gradient overlay */
        z-index: 1;
    }

    &:hover {
        transform: scale(1.02);
    }

    /* Ensure that the content is on top of the pseudo-element */
    > * {
        position: relative;
        z-index: 2;
    }
`;

const Title = styled.p`
    color: #D9D9D9;
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    user-select: none;
`;

const Subtitle = styled.p`
    color: #D9D9D9;
    font-size: 16px;
    font-weight: 500;
    margin: 4px 0 0 0;
    padding: 0;
    opacity: 0.8;
    user-select: none;
`;

const ImageSection = styled.div`
    width: 100%;
    height: 100px;
    background-color: #222222;
    margin-bottom: 16px;
    border-radius: 10px;
`

function CourseCard({ id, title, subtitle, r, g, b }) {
    const [isHovered, setIsHovered] = useState(false);
    const [gradient, setGradient] = useState("linear-gradient(135deg, rgba(57, 57, 57, 0.5), rgba(57, 57, 57, 0.5))");
    const containerRef = useRef(null);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/course/${id}`);
    };

    const handleMouseMove = (e) => {
        const rect = containerRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        setGradient(`radial-gradient(circle at ${x}px ${y}px, rgba(${r}, ${g}, ${b}, 0.5), rgba(57, 57, 57, 0.5))`);
    };

    return (
        <Container
            ref={containerRef}
            gradient={gradient}
            isHovered={isHovered}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onMouseMove={handleMouseMove}
            onClick={handleClick}
        >
            <ImageSection/>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
        </Container>
    );
}

export default CourseCard;