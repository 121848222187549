import { useState, useEffect } from 'react';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';


export const useCourseLessonMaterials = (lessonId) => {
    const [lessonMaterials, setLessonMaterials] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const authHeader = useAuthHeader();

    useEffect(() => {
        const fetchLessons = async () => {
            try {
                setError(null);
                
                const response = await fetch(`http://${process.env.REACT_APP_API}/lesson/${lessonId}/materials`, {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${authHeader}`, 
                    },
                    credentials: 'include', // Send cookies with the request
                });

                if (!response.ok) {
                    setLessonMaterials([]);

                    if (response.status === 401) {
                        console.error('Unauthorized! Please log in again.');
                    }

                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();

                if (!data.error) {
                    setLessonMaterials(data.content);
                } else {
                    setLessonMaterials([]);
                    setError(data.errorMessage);
                }
            } catch (err) {
                setLessonMaterials([]);
                setError(err.message);
            } finally {
                console.log(lessonMaterials)
                setLoading(false);
            }
        };

        fetchLessons();
  }, [lessonId]);

  return { lessonMaterials, loading, error };
};