import styled from "styled-components";
import { useState, useEffect } from "react";
import Latex from "react-latex";
import useWebSocket from "api/websockets/useWebsockets";
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import { Button } from "../shadcn/ui/button";
import RacinesTypeI from "./speedExercises/types/RacinesTypeI";
import RacinesTypeII from "./speedExercises/types/RacinesTypeII";
import RacinesTypeIII from "./speedExercises/types/RacinesTypeIII";
import QuestionContent from "./speedExercises/global/QuestionContent";
import QuestionSolution from "./speedExercises/global/QuestionSolution";
import ExerciseReport from "./speedExercises/global/ExerciseReport";


const Container = styled.div`
    margin-top: 20px;
    width: 100%;
    margin-bottom: 10vh;
`;

const Title = styled.h2`
    margin: 0;
    padding: 0;
    font-size: 22px;
    margin-bottom: 4px;
    font-weight: 500;
`;

const ExerciseQuestion = styled.h2`
    margin: 0;
    padding: 0;
    font-size: 18px;
    font-weight: 400;
    color: gray;
    margin-bottom: 8px;
`;

const Body = styled.div`
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    flex-direction: column;
`;

const AnswerTypeSection = styled.div`
    height: 80px;
`

const ButtonsSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 18px;
    gap: 24px;
    user-select: none;
`

const ReportSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 32px;
    gap: 24px;
`

function SpeedExerciseSection({ exercise }) {
    const [speedExoResponse, setSpeedExoResponse] = useState("")
    const [speedExoReports, setSpeedExoReports] = useState([])
    const { message, sendMessage, connected } = useWebSocket(`ws://${process.env.REACT_APP_API}/exercise/${exercise.id}`);
    const auth = useAuthUser();

    useEffect(() => {
        // for racinestypeIII
        // if (speedExoResponse.length === 4) {
        //     const websocketObject = {
        //         username: auth.username,
        //         speedExoId: message.id,
        //         answer: speedExoResponse.slice(0, 2) + '$' + speedExoResponse.slice(2)
        //     };
        
        //     sendMessage(websocketObject);
        //     setSpeedExoResponse('');
        // }

        // for racinestypeI
        if (speedExoResponse == message?.solution) {
            const websocketObject = {
                username: auth.username,
                speedExoId: message.id,
                answer: message.solution
            };
            sendMessage(websocketObject);
            setSpeedExoResponse('');
        }
    }, [speedExoResponse, sendMessage]);

    useEffect(() => {
        if (message?.errorMessage == "lastReport") {
            setSpeedExoReports(message.content)
        }
    }, [message]);

    const nextQuestion = () => {
        const websocketObject = {
            username: auth.username,
            speedExoId: message ? message.id : '',
            answer: speedExoResponse
        };

        sendMessage(websocketObject);
        setSpeedExoResponse('');
    };

    useEffect(() => {
        console.log("Updated speedExoReports:", speedExoReports);
    }, [speedExoReports]);

    // TODO: fix later
    // useEffect(() => {
    //     const handleEnterPress = (event) => {
    //         if (event.key === 'Enter') {
    //             event.preventDefault();
    //             nextQuestion();
    //         }
    //     };
    //     window.addEventListener('keydown', handleEnterPress);

    //     return () => {
    //         window.removeEventListener('keydown', handleEnterPress);
    //     };
    // }, []);

    // if (!connected) {
    //     return <p></p>;
    // }

    return (
        <Container>
            <Title>{exercise.title}</Title>

            <ExerciseQuestion>
                <Latex>{exercise.content}</Latex>
            </ExerciseQuestion>


            <Body>
                <QuestionContent message={message}/>

                <AnswerTypeSection>
                    {(() => {
                        switch (message?.spType) {
                            case 'RacinesTypeI':
                                return (
                                    <RacinesTypeI
                                        response={speedExoResponse}
                                        setResponse={(res) => setSpeedExoResponse(res)}
                                        answerLen={message?.solution.length}
                                    />
                                );
                            case 'RacinesTypeII':
                                return (
                                    <RacinesTypeII
                                        response={speedExoResponse}
                                        setResponse={(res) => setSpeedExoResponse(res)}
                                        answerLen={message?.solution.length}
                                    />
                                );
                            case 'RacinesTypeIII':
                                return (
                                    <RacinesTypeIII
                                        response={speedExoResponse}
                                        setResponse={(res) => setSpeedExoResponse(res)}
                                    />
                                );
                            default:
                                return null;
                        }
                    })()}
                </AnswerTypeSection>

                <ButtonsSection>
                    <QuestionSolution question={message}/>

                    <Button variant="outline" onClick={() => nextQuestion()}>Next</Button>
                </ButtonsSection>

                {/* <ReportSection>
                    {speedExoReports.map((report, index) => (
                        <ExerciseReport key={report.id} report={report} />
                    ))}
                </ReportSection> */}
            </Body>

        </Container>
    );
}

export default SpeedExerciseSection;