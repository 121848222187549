import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { createSwapy } from 'swapy';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 85vh;
    gap: 12px;
`;

const UpperSection = styled.div`
    width: 100%;
    height: 25%;
    display: flex;
    flex-direction: row;
    gap: 12px;
`

const BoxOne = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222222;
    border-radius: 10px;
    width: 50%;
    height: 100%;
    padding: 8px;
`;

const BoxTwo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #222222;
    border-radius: 10px;
    width: 50%;
    height: 100%;
    padding: 8px;
`;

const BoxThree = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 30%;
    background-color: #222222;
    border-radius: 10px;
    width: 100%;
    flex-grow: 1;
    padding: 8px;
`;

const DEFAULT = {
    '1': 'viewA',
    '2': 'viewB',
    '3': 'viewC'
}

const ViewA = styled.div`
    width: 100%;
    height: 100%;
    background-color: blue;
    border-radius: 10px;
`

const ViewB = styled.div`
    width: 100%;
    height: 100%;
    background-color: orange;
    border-radius: 10px;
`

const ViewC = styled.div`
    width: 100%;
    height: 100%;
    background-color: purple;
    border-radius: 10px;
`

function getItemById(itemId) {
    switch (itemId) {
        case 'viewA':
            return <ViewA data-swapy-item="viewA">HEHE</ViewA>
        case 'viewB':
            return <ViewB data-swapy-item="viewB">HII</ViewB>
        case 'viewC':
            return <ViewC data-swapy-item="viewC">HELLO</ViewC>
    }
}

function ExamScreen() {
    const containerRef = useRef(null);
    const slotItems = localStorage.getItem('slotItem') ? JSON.parse(localStorage.getItem('slotItem')) : DEFAULT

    useEffect(() => {
        if (containerRef.current) {
            const swapy = createSwapy(containerRef.current, {
                animation: 'dynamic' // or spring or none
            });

            swapy.onSwap(({ data }) => {
                localStorage.setItem('slotItem', JSON.stringify(data.object))
            })
        }
    }, []);

    return (
        <Container ref={containerRef} id="Container">
            <UpperSection>
                <BoxOne data-swapy-slot="1">
                    {getItemById(slotItems['1'])}
                </BoxOne>

                <BoxTwo data-swapy-slot="2">
                    {getItemById(slotItems['2'])}
                </BoxTwo>
            </UpperSection>

            <BoxThree data-swapy-slot="3">
                {getItemById(slotItems['3'])}
            </BoxThree>
        </Container>
    );
}

export default ExamScreen;