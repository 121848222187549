import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled, { keyframes } from "styled-components";


const animate = keyframes`
    0%, 100% {
        filter: hue-rotate(0deg);
    }
    50% {
        filter: hue-rotate(360deg);
    }
`;

const ProgressBarSection = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 10px;
    height: 0;
    background-color: rgba(255, 255, 255, 0.05);

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 1);
        filter: blur(10px);
    }

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 1);
        filter: blur(30px);
    }
`;

const ScrollPath = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 10px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    animation: ${animate} 5s linear infinite;
`;

function ProgressBar() {
    const location = useLocation();

    useEffect(() => {
        const progress = document.getElementById('progressBarSection');
        const totalHeight = document.body.scrollHeight - window.innerHeight;

        const handleScroll = () => {
            const progressHeight = (window.scrollY / totalHeight) * 100;
            if (progress) {
            progress.style.height = `${progressHeight}%`;
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    if (location.pathname.startsWith('/login') || location.pathname.startsWith('/register') || location.pathname.startsWith('/course')) {
        return null;
    }

    return (
        <>
            {/* <ProgressBarSection id="progressBarSection"/>
            <ScrollPath/> */}
        </>
    );
}

export default ProgressBar;