import styled from "styled-components";
import { useCourseLessonMaterials } from "../../api/courses/useCourseLessonMaterials";
import { useParams } from "react-router-dom";
import VideoSection from "./VideoSection";
import { useSelectedItem } from "../../utilities/navSelectedContent";
import { useEffect, useState } from "react";
import { useLessonExercises } from "api/courses/useLessonExercises";
import SpeedExerciseSection from "./SpeedExerciseSection";
import TitleTransition from "utilities/transitions/TitleTransition";
import ContentTransition from "utilities/transitions/ContentTransition";
import { Separator } from "../shadcn/ui/separator";
import ForPremiumAlert from "./ForPremiumAlert";


const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h1`
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 500;
`

const Message = styled.p`
    color: #888;
    text-align: center;
`;

function LessonSection() {
    const { lessonId } = useParams("lessonId");
    const { lessonMaterials, materialsLoading, error: materialsError } = useCourseLessonMaterials(lessonId);
    const { exercises, exercisesLoading, error: exerciseError } = useLessonExercises(lessonId);
    const { setSelectedItem } = useSelectedItem();

    useEffect(() => {
        if (lessonId) {
            setSelectedItem(lessonId);
        }
    }, [lessonId, setSelectedItem]);

    if (exerciseError === 'HTTP error! status: 401' || materialsError === 'HTTP error! status: 401') {
        return <ForPremiumAlert/>;
    }

    if (!lessonMaterials && lessonMaterials?.length === 0 && !materialsLoading) {
        return <TitleTransition><Message>No materials found.</Message></TitleTransition>;
    }

    return (
        <Container>
            {lessonMaterials?.map((material, index) => (
                <div key={material.id}>
                    <TitleTransition>
                        <Title>{material.title}</Title>
                    </TitleTransition>

                    <ContentTransition>
                        {material.videoURL ? (
                            <VideoSection videoURL={material.videoURL}/>
                        ) : material.pictureURL ? (
                            <div>
                                <p>Picture URL: {material.pictureURL}</p>
                            </div>
                        ) : material.content ? (
                            <div>
                                <p>Content: {material.content}</p>
                            </div>
                        ) : (
                            <p>No media available</p>
                        )}
                    </ContentTransition>
                </div>
            ))}

            <ContentTransition>
                {exercises && exercises.length > 0 && (
                    exercises.map((exercise) => (
                        exercise.exerciseType === "SPEED" && (
                            <>
                                <Separator style={{background: "#ffffff50"}}/>
                                <SpeedExerciseSection key={exercise.id} exercise={exercise}/> 
                            </>
                        )
                    ))
                )}
            </ContentTransition>
        </Container>
    );
};

export default LessonSection;