import styled from 'styled-components';
import { Input } from '../components/shadcn/ui/input';
import { Button } from '../components/shadcn/ui/button';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../components/shadcn/ui/use-toast';
import useRegister from 'api/userAuth/useRegister';
import UserCard from 'components/global/UserCard';
import transition from 'utilities/transitions/transitions';
import createCardData from 'utilities/userCardController';


const ScreenContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    background-color: #0F0F0F;
    border-radius: 10px;
    padding: 0 0px 0px 20px;

    margin-top: -10%;
    position: relative;

    &:after, &:before {
        content: '';
        position: absolute;
        height: calc(100% + 6px);
        width: calc(100% + 6px);
        background: linear-gradient(122deg, ${(props) => props.bgColor});
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        z-index: -1;
        border-radius: 10px;
        box-sizing: border-box;
    }

    &:before {
        filter: blur(1.5rem);
        opacity: 0.5;
    }
    
`

const RegisterContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    background: #0F0F0F;

`;

const Title = styled.h1`
    font-size: 24px;
    font-weight: 600;
    color: white;
    margin-bottom: 32px;
    text-align: center;
`;

const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

const SmallerTitle = styled.h1`
    font-size: 14px;
    font-weight: 500;
    color: white;
    margin-bottom: 8px;
`;

const ErrorText = styled.span`
    color: red;
    font-size: 12px;
    margin-top: 8px;
    margin-bottom: -12px;
`;

const HaveAccountContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    margin-top: -8px;
`;

const NameSection = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`



function RegisterScreen() {
    const [username, setUsername] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [firstNameError, setFirstNameError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const { register, loading, error, success } = useRegister();
    const navigate = useNavigate();
    const { toast } = useToast();
    const [cardData, setCardData] = useState({});

    useEffect(() => {
        const data = createCardData();
        setCardData(data);
    }, []);

    const handleRegister = async () => {
        let valid = true;

        if (firstName.length < 2) {
            setFirstNameError('First Name must be at least 2 characters long.');
            document.getElementById("firstName").focus();
            valid = false;
        } else {
            setFirstNameError('');
        }

        if (username.length < 3) {
            setUsernameError('Username must be at least 3 characters long.');
            document.getElementById("username").focus();
            valid = false;
        } else {
            setUsernameError('');
        }

        if (password.trim() === '') {
            setPasswordError('Password cannot be empty.');
            document.getElementById("password").focus();
            valid = false;
        } else {
            setPasswordError('');
        }

        if (phoneNumber.trim() === '') {
            setPhoneNumberError('Phone number cannot be empty.');
            document.getElementById("phoneNumber").focus();
            valid = false;
        } else {
            setPhoneNumberError('');
        }

        if (valid) {
            await register(firstName, lastName, username, password, email, phoneNumber, cardData)
        }
    };

    const handleHaveAccount = () => {
        navigate("/login");
    }

    useEffect(() => {
        if (success) {
            toast({
                title: "Successfully Registered!",
                description: "Welcome!",
            });
            navigate("/");
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            setUsernameError('');
            setPasswordError('');
            setPhoneNumberError('');

            if (error.includes('Sorry')) {
                toast({
                    title: "Sorry, Only available to Alpha Testers!",
                    description: "If you want to become an early tester, please wait for the next batch!",
                });
            }

            const errorSplit = error.split('\n');

            for (let i = 0; i < errorSplit.length; i++) {
                if (errorSplit[i].toLowerCase().includes('name')) {
                    setUsernameError(errorSplit[i]);
                    document.getElementById("username").focus();
                }
                if (errorSplit[i].toLowerCase().includes('password')) {
                    setPasswordError(errorSplit[i]);
                    document.getElementById("password").focus();
                }   
                if (errorSplit[i].toLowerCase().includes('number')) {
                    setPhoneNumberError(errorSplit[i]);
                    document.getElementById("phoneNumber").focus();
                }
            }
        }
    }, [error]);

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            const formElements = ["firstName", "lastName", "username", "password", "phoneNumber"];
            const currentIndex = formElements.indexOf(e.target.name);
            if (currentIndex !== -1 && currentIndex < formElements.length - 1) {
                document.getElementById(formElements[currentIndex + 1]).focus();
            } else if (currentIndex === formElements.length - 1) {
                handleRegister();
            }
        }
    };

    return (
        <ScreenContainer>
            <Container bgColor={cardData.color}>
                <UserCard firstName={firstName} lastName={lastName} username={username} cardData={cardData}/>

                <RegisterContainer>
                    <Title>Create your Darresni Account</Title>
                    
                    <NameSection>
                        <FormSection>
                            <SmallerTitle>First Name *</SmallerTitle>
                            <Input
                                id="firstName"
                                name="firstName"
                                placeholder="First Name"
                                maxLength={9}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                onKeyDown={handleKeyDown}
                                style={{
                                    borderColor: firstNameError ? "red" : "transparent",
                                    color: "white",
                                    backgroundColor: "#333333",
                                    userSelect: "none",
                                    outline: firstNameError ? "none" : undefined,
                                    boxShadow: firstNameError ? "0 0 0 0.5px red" : undefined
                                }}
                            />
                            {firstNameError && <ErrorText>{firstNameError}</ErrorText>}
                        </FormSection>

                        <FormSection>
                            <SmallerTitle>Last Name</SmallerTitle>
                            <Input
                                id="lastName"
                                name="lastName"
                                placeholder="Last Name"
                                maxLength={10}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                                onKeyDown={handleKeyDown}
                                style={{
                                    borderColor: "transparent",
                                    color: "white",
                                    backgroundColor: "#333333",
                                    userSelect: "none",
                                    outline: undefined,
                                    boxShadow: undefined
                                }}
                            />
                        </FormSection>
                    </NameSection>

                    
                    <FormSection>
                        <SmallerTitle>Username *</SmallerTitle>
                        <Input
                            id="username"
                            name="username"
                            placeholder="Username"
                            value={username}
                            maxLength={15}
                            onChange={(e) => setUsername(e.target.value)}
                            onKeyDown={handleKeyDown}
                            style={{
                                borderColor: usernameError ? "red" : "transparent",
                                color: "white",
                                backgroundColor: "#333333",
                                userSelect: "none",
                                outline: usernameError ? "none" : undefined,
                                boxShadow: usernameError ? "0 0 0 0.5px red" : undefined
                            }}
                        />
                        {usernameError && <ErrorText>{usernameError}</ErrorText>}
                    </FormSection>

                    <FormSection>
                        <SmallerTitle>Password *</SmallerTitle>
                        <Input
                            id="password"
                            name="password"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={handleKeyDown}
                            style={{
                                borderColor: passwordError ? "red" : "transparent",
                                color: "white",
                                backgroundColor: "#333333",
                                userSelect: "none",
                                outline: passwordError ? "none" : undefined,
                                boxShadow: passwordError ? "0 0 0 0.5px red" : undefined
                            }}
                        />
                        {passwordError && <ErrorText>{passwordError}</ErrorText>}
                    </FormSection>

                    <FormSection>
                        <SmallerTitle>Phone Number *</SmallerTitle>
                        <Input
                            id="phoneNumber"
                            name="phoneNumber"
                            placeholder="Phone Number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            onKeyDown={handleKeyDown}
                            style={{
                                borderColor: phoneNumberError ? "red" : "transparent",
                                color: "white",
                                backgroundColor: "#333333",
                                userSelect: "none",
                                outline: phoneNumberError ? "none" : undefined,
                                boxShadow: phoneNumberError ? "0 0 0 0.5px red" : undefined
                            }}
                        />
                        {phoneNumberError && <ErrorText>{phoneNumberError}</ErrorText>}
                    </FormSection>

                    <HaveAccountContainer>
                        <Button variant="link" style={{ color: "gray" }} onClick={handleHaveAccount}>Already have an Account? Sign In</Button>
                    </HaveAccountContainer>

                    <Button onClick={handleRegister} disabled={loading} style={{ fontWeight: "700", userSelect: "none" }}>
                        {loading ? 'Registering...' : 'Register'}
                    </Button>
                </RegisterContainer>
            </Container>

        </ScreenContainer>
    );
}

export default transition(RegisterScreen);