import { useState, useEffect } from 'react';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';


const useCourses = () => {
    const [courses, setCourses] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const authHeader = useAuthHeader()

    useEffect(() => {
        const getCourses = async () => {
            const response = await fetch(`http://${process.env.REACT_APP_API}/courses`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `${authHeader}`, 
                },
                credentials: 'include', // Send cookies with the request
            });

            if (!response.ok) {
                // Handle specific HTTP status codes if necessary
                if (response.status === 401) {
                  console.error('Unauthorized! Please log in again.');
                  // Optionally, redirect to login page or clear session
                }
                throw new Error(`HTTP error! status: ${response.status}`);
              }
          
              const result = await response.json();

            if (result.error) {
                setError(result.errorMessage);
            } else {
                setCourses(result.content);
            }
            
            setLoading(false);
        };

        getCourses();
    }, []);

    return { courses, error, loading };
};

export default useCourses;