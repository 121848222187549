import styled from "styled-components";


const Container = styled.div`
    background-color: #222222;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 16px;
    display: flex;
    flex-direction: column;
`

const Title = styled.h1`
    font-size: 22px;
    font-weight: 600;
    color: white;
    padding-bottom: 4px;
`

const HightlitedText = styled.span`
    font-weight: bold;
    color: #22d3ee;
    font-size: 20px;
`

const SubTitle = styled.h1`
    font-size: 16px;
    font-weight: 500;
    color: white;
`

function StepTwo() {


    return (
        <Container>
            <Title>Step 2 - <HightlitedText>⏰ Unlimited</HightlitedText></Title>
            <SubTitle>Navigate the website</SubTitle>
        </Container>
    );
}

export default StepTwo;