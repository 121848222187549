import { useState, useEffect } from 'react';

export const useCourseExercises = (courseId) => {
    const [exercises, setExercises] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchExercises = async () => {
            try {
                const response = await fetch(`http://${process.env.REACT_APP_API}/course/${courseId}/exercises`);
                const data = await response.json();

                if (!data.error) {
                    setExercises(data.content);
                } else {
                    setError(data.errorMessage);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchExercises();
    }, [courseId]);

    return { exercises, loading, error };
};