import { useEffect } from 'react';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useUserStore from 'stores/userUserStore';

const useUserDetails = () => {
    const setUser = useUserStore((state) => state.setUser);
    const setError = useUserStore((state) => state.setError);
    const setLoading = useUserStore((state) => state.setLoading);
    
    const authHeader = useAuthHeader();
    const authUser = useAuthUser();

    useEffect(() => {
        const getUser = async () => {
            setLoading(true);

            try {
                const response = await fetch(`http://${process.env.REACT_APP_API}/user/${authUser.username}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${authHeader}`, 
                    },
                    credentials: 'include',
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        console.error('Unauthorized! Please log in again.');
                    }
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const result = await response.json();

                if (result.error) {
                    setError(result.errorMessage);
                } else {
                    setUser(result.content);
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        getUser();
    }, []);
};

export default useUserDetails;