import styled from 'styled-components';
import CourseCard from '../components/homescreen/CourseCard';
import { hexToRgb } from '../utilities/hexToRGB';
import useCourses from '../api/courses/useCourses';
import { useEffect } from 'react';
import { useSelectedItem } from '../utilities/navSelectedContent';
import transition from 'utilities/transitions/transitions';
import useSearchStore from 'stores/useSearchStore';
import { Separator } from '../components/shadcn/ui/separator';
import useUserDetails from 'api/user/useUserDetails';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const CardContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Title = styled.h1`
    color: white;
    font-size: 32px;
    font-weight: 600;
`

const Subtitle = styled.h2`
    color: white;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 24px;
`

function HomeScreen() {
    const { courses, error, loading } = useCourses();
    const { selectedItem, setSelectedItem, clearSelectedItem } = useSelectedItem();
    const searchQuery = useSearchStore((state) => state.searchQuery);

    const filteredCourses = courses.filter((course) =>
        // TODO: arranger later
        course.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        clearSelectedItem();
    }, [clearSelectedItem]);

    useUserDetails();


    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
       <Container>
            <TitleContainer>
                <Title>Courses</Title>
                <Subtitle>Challenging and well guided courses</Subtitle>
                <Separator style={{boxShadow: "0 30px 40px rgba(0,0,0,.1)"}}/>
            </TitleContainer>

            <br/>
            <br/>
            <br/>

            <CardContainer>
                {filteredCourses.map((course) => (
                    <CourseCard
                        key={course.id}
                        id={course.id}
                        title={course.title}
                        subtitle={course.description}
                        r={hexToRgb(course.accentColor).r}
                        g={hexToRgb(course.accentColor).g}
                        b={hexToRgb(course.accentColor).b}
                    />
                ))}

                {filteredCourses.map((course) => (
                    <CourseCard
                        key={course.id}
                        id={course.id}
                        title={course.title}
                        subtitle={course.description}
                        r={hexToRgb(course.accentColor).r}
                        g={hexToRgb(course.accentColor).g}
                        b={hexToRgb(course.accentColor).b}
                    />
                ))}

                {filteredCourses.map((course) => (
                    <CourseCard
                        key={course.id}
                        id={course.id}
                        title={course.title}
                        subtitle={course.description}
                        r={hexToRgb(course.accentColor).r}
                        g={hexToRgb(course.accentColor).g}
                        b={hexToRgb(course.accentColor).b}
                    />
                ))}
            </CardContainer>
       </Container>      
    );
}

export default transition(HomeScreen);