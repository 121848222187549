import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './testing/reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { SelectedItemProvider } from './utilities/navSelectedContent';
import  AuthProvider  from 'react-auth-kit';
import createStore from 'react-auth-kit/createStore';
import { SkeletonTheme } from 'react-loading-skeleton';

const store = createStore({
  authName:'_auth',
  authType:'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: false, // Set True in Developemnt
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider store={store}>
      <SkeletonTheme baseColor="#313131" highlightColor="#525252">
        <SelectedItemProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </SelectedItemProvider>
      </SkeletonTheme>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
