import styled from "styled-components";
import { useSelectedItem } from "../../utilities/navSelectedContent";
import { useEffect } from "react";
import { useCourseIntroduction } from "../../api/courses/useCourseIntroduction";
import TitleTransition from "utilities/transitions/TitleTransition";
import ContentTransition from "utilities/transitions/ContentTransition";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";


const Title = styled.h1`
    margin: 0;
    padding: 0;
    padding-bottom: 12px;
    font-size: 24px;
    font-weight: 500;
`

const Body = styled.p`
    margin: 0;
    padding: 0;
    font-weight: 400;
    font-size: 16px;
`

function CourseIntroduction({courseId}) {
    const { clearSelectedItem } = useSelectedItem();
    const { introduction, loading, error } = useCourseIntroduction(courseId);
    
    const title = introduction?.title || "Introduction"
    const content = introduction?.content || "";

    useEffect(() => {
        clearSelectedItem();
    }, [clearSelectedItem]);

    if (loading) { return <></>; }
    if (error) return <p>Error: {error}</p>;

    return (
        <>
            <TitleTransition>
                <Title>{title || <Skeleton/>}</Title>
            </TitleTransition>

            <ContentTransition>
                <Body>{content || <Skeleton count={10}/>}</Body>
            </ContentTransition>
        </>
    );
}

export default CourseIntroduction;