import styled from "styled-components";
import Latex from "react-latex";
import { useSingleExercise } from "../../api/courses/single/useSingleExercise";
import { useParams } from "react-router-dom";
import { useSelectedItem } from "../../utilities/navSelectedContent";
import { useEffect } from "react";
import TitleTransition from "utilities/transitions/TitleTransition";
import ForPremiumAlert from "./ForPremiumAlert";
import ContentTransition from "utilities/transitions/ContentTransition";


const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ExerciseTitle = styled.h1`
    padding-bottom: 12px;
    font-size: 24px;
    font-weight: 500;
`

const Body = styled.p`
    margin: 0;
    padding: 0;
`

const SolutionTitle = styled.h1`
    margin: 0;
    padding: 0;
    margin-top: 24px;
    padding-bottom: 12px;
    color: grey;
    font-size: 22px;
`

const Message = styled.p`
    color: #888;
    text-align: center;
`;

function ExerciseSection() {
    const { exerciseId } = useParams();
    const { exercise, exerciseLoading, error: exerciseError } = useSingleExercise(exerciseId);
    const { setSelectedItem } = useSelectedItem();

    useEffect(() => {
        if (exerciseId) {
            setSelectedItem(exerciseId);
        }
    }, [exerciseId, setSelectedItem]);

    if (exerciseError === 'HTTP error! status: 401') {
        return <ForPremiumAlert/>;
    }

    if (exerciseLoading) { return <div>Loading...</div>; }

    if (exerciseError) { return <div>Error: {exerciseError.message}</div>; }

    if (exercise == null && exercise?.length === 0 && !exerciseLoading) { 
        return <TitleTransition><Message>No exercise found.</Message></TitleTransition>; 
    }
    
    return (
        <Container>
            {
                exercise?.length > 0 && (
                    <>
                        <TitleTransition>
                            <ExerciseTitle>{exercise[0].title}</ExerciseTitle>
                        </TitleTransition>

                        <ContentTransition>
                            <Body>
                                <Latex>{exercise[0].content}</Latex>
                            </Body>

                            <SolutionTitle>Solution</SolutionTitle>
                            <Body style={{color:"grey"}}>
                                <Latex>{exercise[0].solution}</Latex>
                            </Body>
                        </ContentTransition>
                    </>
                )
            }
        </Container>
    );
}

export default ExerciseSection;