import styled, { keyframes, css } from "styled-components";
import LandingPageNavBar from "components/landingPage/LandingPageNavBar";
import transition from 'utilities/transitions/transitions';
import { Button } from "../components/shadcn/ui/button";
import { Separator } from "../components/shadcn/ui/separator";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";


const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // border: 1px solid red;
    gap: 50px;
`

const CatchPhrase = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const CatchTitle = styled.h1`
    font-size: 44px;
    font-weight: 700;
    color: white;
`
const CatchSubTitle = styled.h1`
    font-size: 25px;
    font-weight: 500;
    color: white;
`

const gradientAnimation = keyframes`
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
`;

const HighlightedText = styled.span`
    font-weight: bold;
    background: linear-gradient(to right, #2596be, #00ffcc, #2596be);
    background-size: 200% 200%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    animation: ${gradientAnimation} 5s ease-in-out infinite alternate;
    
    /* Optional: Fallback color for non-supporting browsers */
    color: #0ea5e9;
`;

const HeroSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60%;
    text-align: center;
    gap: 32px;

    padding: 16px;
    border-radius: 10px;

    background: rgba(34,34,34,0.3);
    -webkit-backdrop-filter: blur(9px);
    backdrop-filter: blur(9px);
`

const HeroText = styled.h1`
    font-size: 18px;
    color: #ffffff80;
    font-weight: 400;
`

const TarbacheSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    // background-color: #222222;
    border-radius: 10px;
    padding: 12px;
    text-align: center;
    gap: 8px;
`

const TarbacheTitle = styled.h1`
    font-size: 24px;
    font-weight: 600;
    color: white;
`

const TarbacheSubTitle = styled.h1`
    font-size: 18px;
    font-weight: 600;
    color: #ffffff80;
`

const PricingSection = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 50px;
    width: 100%;
`

const PricingCardSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`

const PricingOptionCard = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 32px;
    align-items: flex-start;
    position: relative;
    background-color: rgba(10, 10, 10, 1);

    /* Apply gradient or solid border based on the prop */
    ${({ isSolidBorder }) =>
        isSolidBorder
            ? css`
                  border: 1px solid #676767;
              `
            : css`
                    &:after,
                    &:before {
                        content: '';
                        position: absolute;
                        height: calc(100% + 6px);
                        width: calc(100% + 6px);
                        background-image: conic-gradient(#2596be, #00ffcc, #2596be);
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: -1;
                        border-radius: 10px;
                        box-sizing: border-box;
                    }

                    &:before {
                        filter: blur(1rem);
                        opacity: 0.5;
                    }
              `}
`;

const PricingTitle = styled.h1`
    font-size: 32px;
    font-weight: 600;
    color: white;
`

const PricingCardTitle = styled.h1`
    font-size: 22px;
    color: white;
    font-weight: 600;
`

const PricingCardSubTitle = styled.h1`
    font-size: 16px;
    color: #676767;
    font-weight: 500;
`

const PricingCardPrice = styled.h1`
    font-size: 32px;
    font-weight: 700;
    color: white;
    padding-top: 24px;
    padding-bottom: 24px;
`

const PricingCardMonth = styled.span`
    font-size: 14px;
    font-weight: 500;
    color: #676767;
`

const PricingCardFeatures = styled.div`
    display: flex;
    flex-direction: column;
`

const AnimatedButton = styled.button`
    font-size: 16px;
    font-weight: 500;
    color: white;
    padding: 12px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    background: linear-gradient(
        45deg,
        #0ea5e9,
        #0891b2,
        #0d9488,
        #059669,
        #0ea5e9
    );
    background-size: 200% 200%;
    animation: ${gradientAnimation} 7s ease-in-out infinite;

    /* Optional: Add hover and focus styles */
    &:hover {
        opacity: 0.8;
    }
`;

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    color: white;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 12vh;
    padding: 8px;
    gap: 24px;
`


function LandingPageScreen() {
    const navigate = useNavigate();
    const pricingSectionRef = useRef(null);
    const tarbacheSectionRef = useRef(null);

    const scrollToTarbache = () => {
        if (tarbacheSectionRef.current) {
            tarbacheSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToPricing = () => {
        if (pricingSectionRef.current) {
            pricingSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <Container>
            <LandingPageNavBar onScrollToPricing={scrollToPricing} onScrollToTarbache={scrollToTarbache}/>

            <Body>
                <CatchPhrase>
                    <CatchTitle>REDISCOVER LEARNING.</CatchTitle>
                    <CatchSubTitle>
                        Your 
                        <HighlightedText> LAST Lebanese Brevet </HighlightedText> 
                         Private Tutor
                    </CatchSubTitle>
                </CatchPhrase>

                <HeroSection>
                    <HeroText>Darresni provides advanced tools and technology to enhance self-learning, offering students a faster, more personalized educational journey.</HeroText>
                    <AnimatedButton onClick={() => navigate("/login")}>Access the Alpha Release</AnimatedButton>
                </HeroSection>

                <br/>
                <br/>
                <Separator style={{background: "#ffffff80"}} id="tarbache" ref={tarbacheSectionRef}/>
                <br/>
                <br/>

                {/* <TarbacheSection>
                    <PricingTitle>Designed<br/> <HighlightedText>for the Lebanese Brevet.</HighlightedText></PricingTitle>
                    <TarbacheSubTitle>Streamlined and intuitive, leProjet adapts to how you learn and grow.</TarbacheSubTitle>
                </TarbacheSection>

                <br/>
                <br/>
                <Separator style={{background: "#ffffff80"}}/>
                <br/>
                <br/> */}

                <TarbacheSection>
                    <PricingTitle>A platform that goes beyond meeting your study needs —<br/> <HighlightedText>it anticipates them.</HighlightedText></PricingTitle>
                    <TarbacheSubTitle>Powered by AI and advanced Technologies, Darresni adapts to how you learn and grow.</TarbacheSubTitle>
                </TarbacheSection>

                <br/>
                <br/>
                <Separator style={{background: "#ffffff80"}}/>
                <br/>
                <br/>

                <TarbacheSection>
                    <PricingTitle>The private tutor replacement that<br/> <HighlightedText>you have been waiting for.</HighlightedText></PricingTitle>
                </TarbacheSection>

                


                <br/>
                <br/>
                <Separator style={{background: "#ffffff80"}}/>
                <br/>
                <br/>

                <PricingSection id="pricing" ref={pricingSectionRef}>
                    <PricingTitle>Worry about your education,<br/> <HighlightedText>not your bill.</HighlightedText></PricingTitle>

                    <PricingCardSection>
                        <PricingOptionCard isSolidBorder={true}>
                            <PricingCardTitle>Free</PricingCardTitle>
                            <PricingCardSubTitle>Everything you need to try it!</PricingCardSubTitle>

                            <PricingCardPrice>$0 <PricingCardMonth>/month</PricingCardMonth></PricingCardPrice>

                            <Button style={{width: "100%"}} variant="outline">Get Started</Button>

                            {/* <PricingCardFeatures>
                                feature 1
                            </PricingCardFeatures> */}
                        </PricingOptionCard>

                        <PricingOptionCard isSolidBorder={false}>
                            <PricingCardTitle>Premium</PricingCardTitle>
                            <PricingCardSubTitle>Best value to get the full experience!</PricingCardSubTitle>

                            <PricingCardPrice>$ <PricingCardMonth>/month</PricingCardMonth></PricingCardPrice>

                            <Button style={{width: "100%"}}>Get Started</Button>
                        </PricingOptionCard>


                    </PricingCardSection>

                </PricingSection>

                <br/>
                <br/>
                <br/>
                <br/>

                <Footer>
                    <Separator style={{background: "#ffffff60"}}/>
                    Darresni - ©2024
                </Footer>
                
            </Body>
        </Container>
    );
}

export default transition(LandingPageScreen);