import { useState, useEffect, useRef } from 'react';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';


const useWebSocket = (url) => {
    const [message, setMessage] = useState(null);
    const [connected, setConnected] = useState(false);
    const ws = useRef(null);
    const authHeader = useAuthHeader()

    useEffect(() => {
        // Create WebSocket connection
        ws.current = new WebSocket(url+`?token=${authHeader.substring(7)}`);

        // Connection opened
        ws.current.onopen = () => {
            console.log('WebSocket connected');
            setConnected(true);
        };

        // Listen for messages
        ws.current.onmessage = (event) => {
            try {
            const data = JSON.parse(event.data);
            setMessage(data);
            } catch (error) {
            console.error('Failed to parse message as JSON:', error);
            }
        };

        // Connection closed
        ws.current.onclose = () => {
            console.log('WebSocket disconnected');
            setConnected(false);
        };
        
        // Cleanup function
        return () => {
            ws.current.close();
        };
    }, [url]);

    // Send a message to the WebSocket
    const sendMessage = (data) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
        try {
            ws.current.send(JSON.stringify(data));
        } catch (error) {
            console.error('Failed to send message as JSON:', error);
        }
    }
    };

    return { message, sendMessage, connected };
};

export default useWebSocket;