import styled from "styled-components";
import Latex from "react-latex";


const Question = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
`;

function QuestionContent ({ message }) {
    const textColor = (() => {
      switch (message?.content) {
        case "Wrong!":
          return "red";
        case "Correct!":
          return "green";
        default:
          return "white";
      }
    })();

    const content = typeof message?.content === "string" ? message.content : "...";
  
    return (
        <>
        {textColor === "white" ? (
            <Question>
                <Latex>{content}</Latex>
            </Question>
        ) : (
          <Question style={{ color: textColor }}>{content}</Question>
        )}
      </>
    );
};

export default QuestionContent;