import { motion } from "framer-motion";
import "../../App.css"

const transition = (OgComponent) => {
    return () => (
        <>
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
            >
                <OgComponent/>
            </motion.div>
        </>
    )
}

export default transition;