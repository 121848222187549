import { useState, useEffect } from 'react';

export function useSingleCourse(id) {
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchCourse() {
      try {
        const response = await fetch(`http://${process.env.REACT_APP_API}/course/${id}`);
        const data = await response.json();
        setCourse(data.content);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }

    fetchCourse();
  }, [id]);

  return { course, loading, error };
}