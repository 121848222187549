import styled from "styled-components";
import Latex from "react-latex";
import { useParams } from "react-router-dom";
import { useSingleMaterial } from "../../api/courses/single/useSingleMaterial";
import { useSelectedItem } from "../../utilities/navSelectedContent";
import { useEffect } from "react";
import TitleTransition from "utilities/transitions/TitleTransition";
import ContentTransition from "utilities/transitions/ContentTransition";


const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Title = styled.h1`
    font-size: 24px;
    font-weight: 500;
    padding-bottom: 12px;
`

const Body = styled.p`
    margin: 0;
    padding: 0;
`

function MaterialSection() {
    const { materialId } = useParams();
    const { material, materialsLoading, materialsError } = useSingleMaterial(materialId);
    const { setSelectedItem } = useSelectedItem();

    useEffect(() => {
        if (materialId) {
            setSelectedItem(materialId);
        }
    }, [materialId, setSelectedItem]);

    if (materialsLoading) { return <div>Loading...</div>; }

    if (materialsError) { return <div>Error: {materialsError.message}</div>; }

    if ((!material || material.length === 0) && !materialsLoading) { return <div>No material found.</div>; }
    
    return (
        <Container>
            <TitleTransition>
                <Title>{material.title}</Title>
            </TitleTransition>
            
            <ContentTransition>
                <Body>
                    <Latex>{material.content}</Latex>
                </Body>
            </ContentTransition>
        </Container>
    );
}

export default MaterialSection;