import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelectedItem } from "../../utilities/navSelectedContent";
import { Avatar, AvatarFallback, AvatarImage } from "../shadcn/ui/avatar";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger,
} from "../shadcn/ui/dropdown-menu";
import { Dialog } from "../shadcn/ui/dialog";
import { useState, useCallback } from "react";
import MyProfile from "./navbarSection/MyProfile";
import SearchBar from "components/homescreen/SearchBar";
import useSearchStore from "stores/useSearchStore";
import Logo from "../../assets/LOGO.svg";
import MySubscription from "./navbarSection/MySubscription";
import Support from "./navbarSection/Support";
import SignOut from "./navbarSection/SignOut";
import useUserStore from "stores/userUserStore";


const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 16px;
    border-radius: 10px;

    height: 80px;

    position: sticky;
    top: 0px;
    z-index: 900;

    background: rgba(10,10,10,0.5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(13,13,13,0.25);

    width: 100%;
`;

const RightSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
`

const RightButtonSection = styled.div`
    display: flex;
    flex-direction: row;
`;

const AvatarAccount = styled.div`
    position: relative;
`

const AvatarFreePremium = styled.div`
    position: absolute;
    top: -12px;
    left: 28px;
    font-size: 22px;
    user-select: none;
`

export const StyledLogo = styled.img`
    width: 80px;
    height: 80px;
    cursor: pointer;
    transition: transform 0.3s ease;
    user-select: none;

    &:hover {
        transform: scale(1.05);
    }
`;

function NavBar() {
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedItem, setSelectedItem, clearSelectedItem } = useSelectedItem();
    const [openDialog, setOpenDialog] = useState(null);
    const searchQuery = useSearchStore((state) => state.searchQuery);
    const setSearchQuery = useSearchStore((state) => state.setSearchQuery);
    const user = useUserStore((state) => state.user);

    function handleNavigation() {
        navigate("/");
        clearSelectedItem();
    }

    const handleSearch = useCallback((query) => {
        setSearchQuery(query);
    }, [setSearchQuery]);

    function formatLabel(label) {
        if (label) {
            label = label.trim();
    
            if (label.length > 2 && label.split(" ").length > 1) {
                const words = label.split(" ");
                const firstWord = words[0];
                const lastWord = words[words.length - 1];
    
                return firstWord.charAt(0).toUpperCase() + lastWord.charAt(0).toUpperCase();
            }
    
            if (label.length > 2) {
                return label.charAt(0).toUpperCase();
            }
    
            return label.toUpperCase();
        }
    
        return null;
    }

    if (location.pathname.startsWith('/login') || location.pathname.startsWith('/register') || location.pathname.startsWith('/home') || location.pathname.startsWith('/onboarding')) {
        return null;
    }

    return (
        <Container>
            <StyledLogo src={Logo} onClick={handleNavigation} alt="logo"/>

            <RightSection>
                
                <SearchBar onSearch={handleSearch}/>

                <RightButtonSection>
                    <DropdownMenu>
                        <DropdownMenuTrigger>
                            <AvatarAccount>
                                <Avatar>
                                    <AvatarFallback>{formatLabel(user?.firstName[0] + " " + user?.lastName[0])}</AvatarFallback>
                                </Avatar>

                                <AvatarFreePremium>{user?.isPremium ? '👑' : '🆓'}</AvatarFreePremium>
                            </AvatarAccount>
                        </DropdownMenuTrigger>

                        <DropdownMenuContent style={{zIndex: "901"}}>
                            <DropdownMenuLabel>My Account</DropdownMenuLabel>
                            <DropdownMenuSeparator />

                            <DropdownMenuItem onClick={() => setOpenDialog('profile')}>Profile</DropdownMenuItem>
                            <DropdownMenuItem onClick={() => setOpenDialog('billing')}>Subscription</DropdownMenuItem>
                            <DropdownMenuItem onClick={() => setOpenDialog('support')}>Support</DropdownMenuItem>
                            <DropdownMenuItem style={{ backgroundColor: "#ef4444", color: "white" }} onClick={() => setOpenDialog('signout')}>Sign Out</DropdownMenuItem>                                
                        </DropdownMenuContent>

                        {openDialog === 'profile' && (
                            <Dialog open={openDialog === 'profile'} onOpenChange={() => setOpenDialog(null)}>
                                <MyProfile/>
                            </Dialog>
                        )}

                        {openDialog === 'billing' && (
                            <Dialog open={openDialog === 'billing'} onOpenChange={() => setOpenDialog(null)}>
                                <MySubscription isPremium={user?.isPremium}/>
                            </Dialog>
                        )}

                        {openDialog === 'support' && (
                            <Dialog open={openDialog === 'support'} onOpenChange={() => setOpenDialog(null)}>
                                <Support/>
                            </Dialog>
                        )}

                        {openDialog === 'signout' && (
                            <Dialog open={openDialog === 'signout'} onOpenChange={() => setOpenDialog(null)}>
                                <SignOut/>
                            </Dialog>
                        )}
                    </DropdownMenu>
                </RightButtonSection>
            </RightSection>
        </Container>
    )
};

export default NavBar;