import {
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogFooter
} from "../../shadcn/ui/dialog";
import { Button } from "../../shadcn/ui/button";
import useSignOut from 'react-auth-kit/hooks/useSignOut';
import { useNavigate } from "react-router-dom";


function SignOut() {
    const navigate = useNavigate();
    const signOut = useSignOut();

    const handleSignOut = () => {
        signOut();
        navigate('/login');
    };

    return (
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Sign Out</DialogTitle>
                <DialogDescription>Are you sure?</DialogDescription>
            </DialogHeader>

            <DialogFooter>
                <Button variant="destructive" onClick={() => handleSignOut()}>Sign Out</Button>
            </DialogFooter>
        </DialogContent>
    );
}

export default SignOut;