import styled from "styled-components";
import { Input } from "../components/shadcn/ui/input";
import { Button } from "../components/shadcn/ui/button";
import { useState, useEffect } from "react";
import useLogin from "api/userAuth/useLogin";
import { useNavigate } from "react-router-dom";
import { useToast } from "../components/shadcn/ui/use-toast";
import useIsAuthenticated from 'react-auth-kit/hooks/useIsAuthenticated'
import transition from 'utilities/transitions/transitions';


const ScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    background: #0F0F0F;
    margin-top: -20%;
    position: relative;

    &:after, &:before {
        content: '';
        position: absolute;
        height: calc(100% + 6px);
        width: calc(100% + 6px);
        background-image: conic-gradient(#2596be, #00ffcc, #2596be);
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        z-index: -1;
        border-radius: 10px;
        box-sizing: border-box;
    }

    &:before {
        filter: blur(1.5rem);
        opacity: 0.5;
    }
`;

const Title = styled.h1`
    font-size: 24px;
    font-weight: 600;
    color: white;
    margin-bottom: 32px;
    text-align: center;
`;

const FormSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
`;

const SmallerTitle = styled.h1`
    font-size: 14px;
    font-weight: 500;
    color: white;
    margin-bottom: 8px;
`;

const ErrorText = styled.span`
    color: red;
    font-size: 12px;
    margin-top: 8px;
`;

const NoAccountContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
    margin-top: -8px;
`;

function LoginScreen() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [usernameError, setUsernameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const { login, loading, error, token, success } = useLogin();
    const navigate = useNavigate()
    const { toast } = useToast()
    const isAuthenticated = useIsAuthenticated()

    const handleLogin = async () => {
        let valid = true;

        if (username.length < 3) {
            setUsernameError('Username must be at least 3 characters long.');
            document.getElementById("username").focus();
            valid = false;
        } else {
            setUsernameError('');
        }

        if (password.trim() === '') {
            setPasswordError('Password cannot be empty.');
            document.getElementById("password").focus();
            valid = false;
        } else {
            setPasswordError('');
        }

        if (valid) {
            await login(username, password);
        }
    };

    const handleNoAccount = () => {
        navigate("/register");
    }

    useEffect(() => {
        if (success) {
            toast({
                title: "Sucessfully Logged In!",
                description: "King",
            })
            navigate("/")
        }
    }, [success]);

    useEffect(() => {
        if (error) {
            if (error.toLowerCase().includes('user')) {
                setUsernameError(error);
                document.getElementById("username").focus();
            } else if (error.toLowerCase().includes('password')) {
                setPasswordError(error);
                document.getElementById("password").focus();
            } else {
                // Handle general errors if necessary
                setUsernameError('');
                setPasswordError('');
            }
        }
    }, [error]);

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            if (e.target.name === "username") {
                document.getElementById("password").focus();
            } else if (e.target.name === "password") {
                handleLogin();
            }
        }
    };

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/')
        }
    });

    return (
        <ScreenContainer>
            <Container>
                <Title>Log in to Darresni</Title>
                
                <FormSection>
                    <SmallerTitle>Username</SmallerTitle>
                    <Input
                        id="username"
                        name="username"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        onKeyDown={handleKeyDown}
                        style={{
                            borderColor: usernameError ? "red" : "transparent",
                            color: "white",
                            backgroundColor: "#333333",
                            userSelect: "none",
                            outline: usernameError ? "none" : undefined,
                            boxShadow: usernameError ? "0 0 0 0.5px red" : undefined
                        }}
                    />
                    {usernameError && <ErrorText>{usernameError}</ErrorText>}
                </FormSection>

                <FormSection>
                    <SmallerTitle>Password</SmallerTitle>
                    <Input
                        id="password"
                        name="password"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                        style={{
                            borderColor: passwordError ? "red" : "transparent",
                            color: "white",
                            backgroundColor: "#333333",
                            userSelect: "none",
                            outline: passwordError ? "none" : undefined,
                            boxShadow: passwordError ? "0 0 0 0.5px red" : undefined
                        }}
                    />
                    {passwordError && <ErrorText>{passwordError}</ErrorText>}
                </FormSection>

                <NoAccountContainer>
                    <Button variant="link" style={{ color: "gray" }} onClick={handleNoAccount}>No Account? Create one</Button>
                </NoAccountContainer>

                <Button onClick={handleLogin} disabled={loading} style={{ fontWeight: "700", userSelect: "none" }}>{loading ? 'Signing In...' : 'Sign In'}</Button>
            </Container>
        </ScreenContainer>
    );
}

export default transition(LoginScreen);