import styled from "styled-components";


const VideoSectionContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const Video = styled.video`
    width: 800px;
    height: 500px;
`

function VideoSection(videoURL) {
    return (
        <VideoSectionContainer>
            <Video controls>
                <source src={videoURL.videoURL} type="video/mp4"></source>
            </Video>
        </VideoSectionContainer>
    );
}

export default VideoSection;