import { useState, useEffect } from 'react';

export const useCourseMaterials = (courseId) => {
    const [materials, setMaterials] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchLessons = async () => {
            try {
                const response = await fetch(`http://${process.env.REACT_APP_API}/course/${courseId}/materials`);
                const data = await response.json();

                if (!data.error) {
                    setMaterials(data.content);
                } else {
                    setError(data.errorMessage);
                }
            } catch (err) {
                    setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchLessons();
  }, [courseId]);

  return { materials, loading, error };
};