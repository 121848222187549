import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSignIn from 'react-auth-kit/hooks/useSignIn';


const useRegister = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [token, setToken] = useState(null);
    const singIn = useSignIn();

    const register = async (firstName, lastName, username, password, email="", phoneNumber, cardData) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await fetch(`http://${process.env.REACT_APP_API}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username: username, password, email, phoneNumber, cardColor: cardData.color, cardTitle: cardData.userTitle, cardSymbol: cardData.symbol, firstName: firstName, lastName: lastName }),
            });

            const data = await response.json();

            if (data.error) {
                setError(data.errorMessage);
            } else {
                if (!data.content.includes("Registered")) {
                    singIn({
                        auth: {
                            token: data.content,
                            type: 'Bearer'
                        },
                        expiresIn: 36000,
                        userState: { username: username }
                    })
                    setSuccess(true);
                    setToken(data.content);
                }
            }
        } catch (err) {
            setError('Something went wrong');
        } finally {
            setLoading(false);
        }
    };

    return { register, loading, error, success };
};

export default useRegister;