import React, { useEffect, useState } from 'react';
import { useParams, useLocation, Routes, Route, useNavigate } from 'react-router-dom';
import { useSingleCourse } from '../api/courses/single/useSingleCourse';
import { styled, keyframes, css } from 'styled-components';
import CourseOutline from '../components/courseMainScreen/CourseOutline';
import ExerciseSection from '../components/courseMainScreen/ExerciseSection';
import LessonSection from '../components/courseMainScreen/LessonSection';
import MaterialSection from '../components/courseMainScreen/MaterialSection';
import CourseIntroduction from '../components/courseMainScreen/CourseIntroduction';
import { hexToRgb } from '../utilities/hexToRGB';
import transition from 'utilities/transitions/transitions';
import { animate } from 'framer-motion';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import TitleTransition from 'utilities/transitions/TitleTransition';
import { motion } from 'framer-motion';
import LoadingScreen from './LoadingScreen';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  gap: 50px;
`;

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
`;

const getTextShadow = (r, g, b) => `
  0px 0px 5px rgba(${r}, ${g}, ${b}, 0.5),
  0px 0px 10px rgba(${r}, ${g}, ${b}, 0.4),
  0px 0px 15px rgba(${r}, ${g}, ${b}, 0.3)
`;

const createGlowAnimation = (r, g, b) => keyframes`
  0% {
    text-shadow: ${getTextShadow(r, g, b)};
  }
  50% {
    text-shadow: 
      0px 0px 10px rgba(${r}, ${g}, ${b}, 0.5),
      0px 0px 20px rgba(${r}, ${g}, ${b}, 0.4),
      0px 0px 30px rgba(${r}, ${g}, ${b}, 0.3);
  }
  100% {
    text-shadow: ${getTextShadow(r, g, b)};
  }
`;

const Title = styled.h1`
  margin: 0;
  margin-bottom: 12px;
  user-select: none;
  cursor: pointer;
  transition: 
    transform 0.3s ease-in-out, 
    text-shadow 0.3s ease-in-out;
  display: inline-block;
  font-size: 28px;
  font-weight: 500;

  ${({ r, g, b, shouldGlow }) => css`
    &:hover {
      transform: scale(1.01);
      text-shadow: 
        0px 0px 10px rgba(${r}, ${g}, ${b}, 0.4),
        0px 0px 20px rgba(${r}, ${g}, ${b}, 0.3),
        0px 0px 30px rgba(${r}, ${g}, ${b}, 0.2);
    }

    ${shouldGlow && css`
      animation: ${createGlowAnimation(r, g, b)} 1.5s infinite alternate;
    `}
  `}
`;


function CourseDetailScreen() {
  const { id } = useParams();
  const location = useLocation();
  const { course, loading, error } = useSingleCourse(id);
  const navigate = useNavigate();

  const { r, g, b } = hexToRgb(course?.accentColor)

  useEffect(() => {
    // Create overlay div
    const overlay = document.createElement('div');
    overlay.style.position = 'fixed';
    overlay.style.top = 0;
    overlay.style.left = 0;
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.pointerEvents = 'none'; // Allows clicks to pass through
    overlay.style.zIndex = 9999; // Ensure it is on top of everything
    overlay.style.backgroundColor = 'rgba(36, 36, 36, 0)'; // Base background color with transparency
    overlay.style.backgroundImage = `linear-gradient(to bottom, rgba(36, 36, 36, 0) 70%, rgba(${r}, ${g}, ${b}, 0.3) 100%)`;

    // Add overlay to body
    document.body.appendChild(overlay);

    animate(overlay, { opacity: 1 }, { duration: 0.5 });

    // Cleanup function to remove overlay when the component unmounts
    return () => {
      animate(overlay, { opacity: '0' }, { duration: 0.5 }).then(() => {
        document.body.removeChild(overlay);
      });
    };
  }, [r, g, b]);

  const handleCouseIntroNavigation = () => {
    navigate(`/course/${id}`);
  };

  const isCourseRoute = /^\/course\/[^/]+$/.test(location.pathname);

  const title = course?.title || '';

  // if (loading) return <LoadingScreen/>; 

  if (error) return <p>Error: {error.message}</p>;

  return (
    <Container>
      <div>
        <Title onClick={handleCouseIntroNavigation} r={r} g={g} b={b} shouldGlow={isCourseRoute}>
          {title}
        </Title>
      </div>
      

      <SubContainer>
        <CourseOutline courseId={id} title={title} r={r} g={g} b={b}/>

        <ContentContainer>
          <Routes>
            <Route path="lesson/:lessonId" element={<LessonSection />} />
            <Route path="exercise/:exerciseId" element={<ExerciseSection />} />
            <Route path="material/:materialId" element={<MaterialSection />} />
            <Route path="*" element={<CourseIntroduction courseId={id}/>} />
          </Routes>
        </ContentContainer>
      </SubContainer>
    </Container>
  );
}

export default transition(CourseDetailScreen);