import styled from "styled-components";
import { useRef } from "react";
import { getCurrentDate } from "utilities/calendarController";
import { ReactComponent as InfinityIcon } from "../../assets/mathSymbols/infinitySymbol.svg";
import { ReactComponent as Deg45Symbol } from "../../assets/mathSymbols/45degSymbol.svg";
import { ReactComponent as BlockIcon } from "../../assets/mathSymbols/blockSymbol.svg";
import { ReactComponent as FunctionIcon } from "../../assets/mathSymbols/functionSymbol.svg";
import { ReactComponent as ParabolicIcon } from "../../assets/mathSymbols/parabolicSymbol.svg";
import { ReactComponent as PhiIcon } from "../../assets/mathSymbols/phiSymbol.svg";
import { ReactComponent as ProductIcon } from "../../assets/mathSymbols/productSymbol.svg";
import { ReactComponent as SrootIcon } from "../../assets/mathSymbols/srootSymbol.svg";
import Skeleton from "react-loading-skeleton";


const UserCardContainer = styled.div`
    width: 350px;
    height: 400px;
    border-radius: 10px;
    background: linear-gradient(122deg, ${(props) => props.bgColor});
    display: flex;
    flex-direction: column;
    padding: 12px;
    justify-content: space-between;
    user-select: none;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transform-style: preserve-3d;
    perspective: 1000px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.1);
    position: relative; /* Ensure proper positioning for pseudo-elements */
    scale: 0.9;

    /* Apply 3D transformation */
    transform: rotateX(var(--x-rotation)) rotateY(var(--y-rotation));

    /* Smooth transition for hover effects */
    &:hover {
        transform: rotateX(var(--x-rotation)) rotateY(var(--y-rotation)) ;
    }

    /* Radial gradient effect */
    &::before {
        content: "";
        position: absolute;
        inset: 0;
        background: radial-gradient(circle at var(--x) var(--y), rgba(255, 255, 255, 0.2) 10%, transparent 40%);
        pointer-events: none;
        transition: background 0.3s ease-out;
        z-index: 1; /* Ensure it appears above the card content */
        border-radius: 10px;
    }
`;

const UserCardLogo = styled.h1`
    color: white;
    font-size: 22px;
    font-weight: 700;
`;

const UserCardUpperSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const UserCardLowerSection = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
`;

const UserCardName = styled.h1`
    color: white;
    font-size: 28px;
    font-weight: 700;
`;

const UserCardUsername = styled.h1`
    color: #ffffff90;
    font-size: 18px;
    font-weight: 500;
    margin-top: -8px;
    padding-bottom: 8px;
`;

const UserCardLabel = styled.h1`
    color: white;
    font-size: 20px;
    font-weight: 400;
`;

const UserCardDate = styled.h1`
    color: white;
    font-size: 18px;
    font-weight: 400;
`;

const UserCardMiddleSection = styled.div`
    display: flex;
`;

function UserCard({ firstName='', lastName='', username, cardData, date=null }) {
    const boundingRef = useRef(null);

    const handleMouseMove = (e) => {
        if (!boundingRef.current) return;

        const rect = boundingRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        const xPercentage = x / rect.width;
        const yPercentage = y / rect.height;

        const xRotation = (yPercentage - 0.5) * 20;
        const yRotation = (xPercentage - 0.5) * 20;

        boundingRef.current.style.setProperty("--x-rotation", `${xRotation}deg`);
        boundingRef.current.style.setProperty("--y-rotation", `${yRotation}deg`);
        boundingRef.current.style.setProperty("--x", `${xPercentage * 100}%`);
        boundingRef.current.style.setProperty("--y", `${yPercentage * 100}%`);
    };

    const handleMouseLeave = () => {
        if (boundingRef.current) {
            boundingRef.current.style.transition = 'transform 0.3s ease-out';
            boundingRef.current.style.setProperty("--x-rotation", "0deg");
            boundingRef.current.style.setProperty("--y-rotation", "0deg");

            boundingRef.current.style.setProperty("--x", "-100");
            boundingRef.current.style.setProperty("--y", "-100");
        }
    };

    const SymbolComponent = (() => {
        switch (cardData.symbol) {
            case "infinitySymbol":
                return InfinityIcon;
            case "Deg45Symbol":
                return Deg45Symbol;
            case "blockSymbol":
                return BlockIcon;
            case "functionSymbol":
                return FunctionIcon;
            case "parabolicSymbol":
                return ParabolicIcon;
            case "phiSymbol":
                return PhiIcon;
            case "productSymbol":
                return ProductIcon;
            case "srootSymbol":
                return SrootIcon;
            default:
                return InfinityIcon;
        }
    })();

    return (
        <div className="flex flex-col [perspective:1000px]">
            <UserCardContainer
                ref={boundingRef}
                onMouseMove={handleMouseMove}
                onMouseLeave={handleMouseLeave}
                bgColor={cardData.color}
            >
                <UserCardUpperSection>
                    <UserCardLogo>DARRESNI</UserCardLogo>
                    <UserCardDate>{date == null ? getCurrentDate() : date}</UserCardDate>
                </UserCardUpperSection>

                <UserCardMiddleSection>
                    <SymbolComponent style={{ height: "150px" }} />
                </UserCardMiddleSection>

                <UserCardLowerSection>
                    <UserCardName>{firstName.length > 0 ? firstName : 'First Name'} {lastName.length > 0 ? lastName : ''}</UserCardName>
                    <UserCardUsername>@{username.length > 0 ? username : 'Username'}</UserCardUsername>
                    <UserCardLabel>{cardData.userTitle}</UserCardLabel>
                </UserCardLowerSection>
            </UserCardContainer>
        </div>
    );
}

export default UserCard;