import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import LogoGif from "../../assets/LOGO.svg";
import { Button } from "../shadcn/ui/button";


const Container = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;
    height: 90px;

    position: sticky;
    top: 0px;
    z-index: 900;

    background: rgba(10,10,10,0.5);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(13,13,13,0.25);
`;

const LeftSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
`

const RightSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
`

const Logo = styled.img`
    width: 80px;
    height: 80px;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.05);
    }
`;

function LandingPageNavBar({ onScrollToPricing, onScrollToTarbache }) {
    const navigate = useNavigate();

    const scrollToHome = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Container>
            <LeftSection>
                <Logo src={LogoGif} onClick={scrollToHome} alt="logo"/>
                <Button variant="ghost" style={{fontSize: "16px"}} onClick={onScrollToTarbache}>Why Darresni?</Button>
                <Button variant="ghost" style={{fontSize: "16px"}} onClick={onScrollToPricing}>Pricing</Button>
                {/* <Button variant="ghost" style={{fontSize: "16px"}}>About</Button> */}
                <Button variant="outline" style={{fontSize: "16px", cursor: "auto"}}>v. Alpha 0.1</Button>
            </LeftSection>            

            <RightSection>
                <Button variant="outline" onClick={() => navigate("/login")}>Log In</Button>
                <Button onClick={() => navigate("/register")}>Sign Up</Button>
            </RightSection>
        </Container>
    );
}

export default LandingPageNavBar;