import styled from "styled-components";
import {
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from "../../shadcn/ui/dialog";
import useUserSubscription from "api/user/useUserSubscription";


const Body = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
`

const Title = styled.h1`
    font-size: 24px;
    font-weight: 700;
    color: white;
`

const SubTitle = styled.p`
    font-size: 14px;
    font-weight: 600;
    color: white;
    text-align: center;
`

const SubberTitle = styled.p`
    font-size: 12px;
    font-weight: 400;
    color: white;
    text-align: center;
`


function MySubscription({ isPremium }) {
    const { subscription, error, loading } = useUserSubscription();

    return (
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Subscription</DialogTitle>
                <DialogDescription>Your Subscription Plan Information.</DialogDescription>
            </DialogHeader>

            <Body>
                {isPremium ? (
                    <>
                        <Title>Premium 👑</Title>
                        <SubTitle>Valid till {subscription?.endDate}</SubTitle>
                        <SubberTitle>Renew it anytime by depositing at OMT or Wish - 20$/Month</SubberTitle>
                    </>
                ): (
                    <>
                        <Title>FREE 🥲</Title>
                        <SubTitle>Get your premium subcription & Unlock all the perks today!</SubTitle>
                    </>
                )}
            </Body>
        </DialogContent>
    );
}

export default MySubscription;