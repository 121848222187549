import styled from "styled-components";
import {
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from "../../shadcn/ui/dialog";


const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 200px;
    color: white;
`

const BodyItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

function Support() {
    return (
        <DialogContent>
            <DialogHeader>
                <DialogTitle>Support</DialogTitle>
                <DialogDescription>Get support!</DialogDescription>
            </DialogHeader>

            <Body>
                <BodyItem>You won't need support lol</BodyItem>
            </Body>
        </DialogContent>
    );
}

export default Support;