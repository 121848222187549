import { create } from 'zustand';


const useUserStore = create((set) => ({
    user: null,
    error: null,
    loading: false,
    setUser: (user) => set({ user }),
    setError: (error) => set({ error }),
    setLoading: (loading) => set({ loading }),
}));

export default useUserStore;