import styled from "styled-components";
import TitleTransition from "utilities/transitions/TitleTransition";
import LoadingIcon from "assets/fractal_tree.gif";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";


const ScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100wh;
`

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-contents: center;
    align-items: center;
    background-color: #222222;
    border-radius: 10px;
    width: 80%;
    padding: 16px;
`

const Title = styled.h1`
    font-size: 24px;
    font-weight: 600;
    text-align: center;
`

const TreeSection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-height: 100%;
`

function ForPremiumAlert() {
    const [gifSrc, setGifSrc] = useState(`${LoadingIcon}?${Date.now()}`);

    useEffect(() => {
        setGifSrc(`${LoadingIcon}?${Date.now()}`);
    }, []);

    return (
        <TitleTransition>
            <ScreenContainer>
                <Container>
                    <Title>🔒 You need a Premium Subscription to access this content!</Title>

                    {/* <TreeSection>
                        <img src={gifSrc} alt="loading..." style={{ scale: "0.9" }}/>
                        <img src={gifSrc} alt="loading..." style={{ scale: "0.9" }}/>
                        <img src={gifSrc} alt="loading..." style={{ scale: "0.9" }}/>
                    </TreeSection> */}
                </Container>
            </ScreenContainer>
        </TitleTransition>
    );
}

export default ForPremiumAlert;