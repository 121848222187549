import styled from "styled-components";
import Stepone from "assets/onboarding/onb1.svg";
import Steponetwo from "assets/onboarding/onb1_2.svg";


const Container = styled.div`
    background-color: #222222;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 16px;
    display: flex;
    flex-direction: column;
`

const Title = styled.h1`
    font-size: 22px;
    font-weight: 600;
    color: white;
    padding-bottom: 4px;
`

const HightlitedText = styled.span`
    font-weight: bold;
    color: #16a34a;
    font-size: 20px;
`

const SubTitle = styled.h1`
    font-size: 16px;
    font-weight: 500;
    color: white;
`

const ImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 12px;
    user-select: none;
`

const Image = styled.img`
    max-width: 70%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
    border: 1px solid #ffffff80;
`

function StepOne() {
    return (
        <Container>
            <Title>Step 1 -  <HightlitedText>⏰ 2 minutes</HightlitedText></Title>
            <SubTitle>Create your Free Account</SubTitle>
            
            <ImageContainer>
                <Image src={Stepone} alt="onboarding_1"/>
                {/* <Image src={Steponetwo} alt="onboarding_1"/> */}
            </ImageContainer>
        </Container>
    );
}

export default StepOne;