import { useState } from 'react';
import useSignIn from 'react-auth-kit/hooks/useSignIn';


const useLogin = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [token, setToken] = useState(null);
    const [success, setSuccess] = useState(false);
    const singIn = useSignIn();

    const login = async (username, password) => {
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            const response = await fetch(`http://${process.env.REACT_APP_API}/login`, { 
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: username, password }),
            });

            const data = await response.json();

            if (data.error) {
                setError(data.errorMessage);
            } else {
                singIn({
                    auth: {
                        token: data.content,
                        type: 'Bearer'
                    },
                    expiresIn: 36000,
                    userState: { username: username }
                })
                setToken(data.content);
                setSuccess(true);
            }
        } catch (err) {
            console.log(`Something went wrong! ${err}`)
            setError(`Something went wrong! ${err}`);
        } finally {
            setLoading(false);
        }
    };

    return { login, loading, error, token, success };
};

export default useLogin;