import Latex from "react-latex";
import { Button } from "../../../shadcn/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../../../shadcn/ui/dialog";


function QuestionSolution({ question }) {
    return (
        <>
            <Dialog>
                <DialogTrigger><Button variant="secondary">Show Answer</Button></DialogTrigger>
                <DialogContent>
                    <DialogHeader>
                    <DialogTitle>Solution</DialogTitle>
                    <DialogDescription style={{marginTop: "20px",lineHeight: "2"}}>
                        <Latex>{typeof question?.detailedSolution === "string" ? question.detailedSolution : "No Solution Available!"}</Latex>
                    </DialogDescription>
                    </DialogHeader>
                </DialogContent>
            </Dialog>
        </>
    );
}

export default QuestionSolution;