import React, { createContext, useContext, useState, useEffect } from 'react';

const SelectedItemContext = createContext();

export const useSelectedItem = () => useContext(SelectedItemContext);

export const SelectedItemProvider = ({ children }) => {
    const [selectedItem, setSelectedItem] = useState(() => {
        return localStorage.getItem('selectedItem') || null;
    });

    useEffect(() => {
        localStorage.setItem('selectedItem', selectedItem);
    }, [selectedItem]);

    const clearSelectedItem = () => {
        setSelectedItem(null);
    };

    return (
        <SelectedItemContext.Provider value={{ selectedItem, setSelectedItem, clearSelectedItem }}>
            {children}
        </SelectedItemContext.Provider>
    );
};