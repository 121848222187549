import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import {
    InputOTP,
    InputOTPGroup,
    InputOTPSeparator,
    InputOTPSlot,
} from "../../../shadcn/ui/input-otp";


const Container = styled.div`
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
`;

function RacinesTypeI({ response, setResponse, answerLen }) {
    const otpRef = useRef(null);

    useEffect(() => {
        if (otpRef.current) {
            otpRef.current.focus();
        }
    }, []);

    return (
        <Container>
            <InputOTP ref={otpRef} maxLength={2} value={response} onChange={(v) => setResponse(v)} style={{caretColor: "white"}}>
                <InputOTPGroup style={{caretColor: "white"}}>
                    <InputOTPSlot index={0} style={{padding: "10px", fontSize: "20px"}}/>
                    {answerLen === 2 && (
                        <InputOTPSlot index={1} style={{padding: "10px", fontSize: "20px"}}/>
                    )}
                </InputOTPGroup>
            </InputOTP>
        </Container>
    );
}

export default RacinesTypeI;