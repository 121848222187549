import styled from "styled-components";
import { Separator } from "../components/shadcn/ui/separator";
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "components/shadcn/ui/carousel";
import StepOne from "components/onboarding/StepOne";
import StepTwo from "components/onboarding/StepTwo";
import StepThree from "components/onboarding/StepThree";
import transition from "utilities/transitions/transitions";
import LogoGif from "assets/LOGO.svg";
import { useNavigate } from "react-router-dom";
import { Button } from "components/shadcn/ui/button";
  

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    margin-top: 2vh;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    user-select: none;
`

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
`

const Title = styled.h1`
    color: white;
    font-size: 32px;
    font-weight: 600;
`

const Subtitle = styled.h2`
    color: white;
    font-size: 18px;
    font-weight: 300;
    margin-bottom: 24px;
`
const Logo = styled.img`
    width: 80px;
    height: 80px;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.05);
    }
`;


function OnboardingScreen() {
    const navigate = useNavigate();

    return (
        <Container>
            <Header>
                <Logo src={LogoGif} onClick={() => navigate("/home")} alt="logo"/>
            </Header>

            <TitleContainer>
                <Title>Get Started Today!</Title>
                <Subtitle>Upgrade your learning experience</Subtitle>
                <Separator style={{boxShadow: "0 30px 40px rgba(0,0,0,.1)"}}/>
            </TitleContainer>

            <Carousel style={{ width: "80%", marginTop: "2vh" }}>
                <CarouselContent>
                    <CarouselItem><StepOne/></CarouselItem>
                    <CarouselItem><StepTwo/></CarouselItem>
                    <CarouselItem><StepThree/></CarouselItem>
                </CarouselContent>

                <CarouselPrevious />
                <CarouselNext />
            </Carousel>

            <TitleContainer style={{width: "30%"}}>
                <Button style={{width: "100%"}} onClick={() => navigate("/register")}>Create your Free Account</Button>
            </TitleContainer>
        
        </Container>
    );
}

export default transition(OnboardingScreen);