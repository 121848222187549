const cardColorAray = [
    "#8a2387 0%, #e94057 50%, #f27121 100%",
    "#e4406f 0%, #ca2374 50%, #9c297f 100%",
    "#4776e6 0%, #8e54e9 100%",
    "#c70039 0%, #900c3f 100%",
    "#f09819 0%, #edde5d 100%",
]

const cardUserTitle = [
    "The Problem Solver 🧠",
    "The Fibonacci Fanatic 🧶",
    "The Number Cruncher 🔢",
    "The Calculator 🧮",
    "The Sigma Σ",
    "The GOAT 🐐"
]

const cardSymbol = [
    "45degSymbol",
    "blockSymbol",
    "functionSymbol",
    "infinitySymbol",
    "phiSymbol",
    "plusminusSymbol",
    "productSymbol",
    "srootSymbol"
]

function createCardData() {
    const randomColorIndex = Math.floor(Math.random() * cardColorAray.length);
    const randomUserTitleIndex = Math.floor(Math.random() * cardUserTitle.length);
    const randomSymbolIndex = Math.floor(Math.random() * cardSymbol.length);

    const cardData = {
        color: cardColorAray[randomColorIndex],
        userTitle: cardUserTitle[randomUserTitleIndex],
        symbol: cardSymbol[randomSymbolIndex]
    };

    if (localStorage.getItem("cardData")) {
        return JSON.parse(localStorage.getItem("cardData"));
    } else {
        localStorage.setItem("cardData", JSON.stringify(cardData));
        return cardData;
    }
}

export default createCardData;