import { motion } from "framer-motion";


const TitleTransition = ({ children }) => {
    return (
        <motion.div
            initial={{y: -35}}
            animate={{y: 0}}
            exit={{y: 0}}
            style={{width: "100%", height: "100%"}}
            transition={{duration: 0.5}}
        >
            {children}
        </motion.div>
    );
};

export default TitleTransition;