import styled from "styled-components";
import {
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "../../shadcn/ui/dialog";
import HeatMap from '@uiw/react-heat-map';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';
import useUserActivity from "api/user/useUserActivity";
import UserCard from "../UserCard";
import useUserCard from "../../../api/user/useUserCard";
import { Separator } from "../../shadcn/ui/separator";
import useUserStore from "stores/userUserStore";
import { formatDate, calculateStartDate } from "utilities/calendarController";
import React, { useState, useEffect } from 'react';
import { Tooltip } from "react-tooltip";
import Skeleton from "react-loading-skeleton";


const ProfileBody = styled.div`
    display: flex;
    flex-direction: row;
`

const LeftSection = styled.div`
    display: flex;
    flex-direction: column;
    scale: 0.9;
    margin: 0;
    padding: 0;
`

const RightSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 20px;
    align-items: center;
    flex-grow: 2;
`

const ActivitySection = styled.div`
    display: flex;
    flex-direction: column;
    padding: 8px;
    border: 0.5px solid #676767;
    border-radius: 10px;
    align-self: flex-start; /* Override any inherited stretch behavior */
    width: 100%;
`

const InformationSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border: 0.5px solid #676767;
    border-radius: 10px;
    align-self: flex-start; /* Override any inherited stretch behavior */
    flex-grow: 1;
    height: 100%;
    color: white;
`

const InformationTitle = styled.h1`
    font-size: 16px;
    color: white;
`
const InformationSubTitle = styled.span`
    font-size: 12px;
    color: white;
`

const ActivityTitle = styled.h1`
    font-size: 14px;
    padding: 8px;
    color: white;
`

const BottomSection = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    gap: 24px;
    justify-content: space-between;
`

function MyProfile() {
    const auth = useAuthUser();
    const { activity, loading } = useUserActivity();
    const { userCard } = useUserCard();
    const user = useUserStore((state) => state.user);
    const [startDate, setStartDate] = useState(calculateStartDate());
    let tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));

    useEffect(() => {
        function updateStartDate() {
            setStartDate(calculateStartDate());
        }

        updateStartDate();

        window.addEventListener('resize', updateStartDate);

        return () => window.removeEventListener('resize', updateStartDate);
    }, []);

    
    return (
        <DialogContent style={{ backgroundColor: "rgba( 10, 10, 10, 1 )", maxWidth: "70%" }}>
            <DialogHeader>
                <DialogTitle style={{ color: "white" }}>Hello, {auth.username}!</DialogTitle>
                <DialogDescription>📅 {user.birthdate}</DialogDescription>
            </DialogHeader>

            <ProfileBody>
                <LeftSection>
                    <UserCard firstName={user.firstName} lastName={user.lastName} username={auth.username} cardData={userCard} date={formatDate(user.userCreatedAt)}/>
                </LeftSection>

                <Separator orientation="vertical" style={{ border: "0.5px solid #676767" }} />

                <RightSection>
                    <ActivitySection>
                        <ActivityTitle>🚀 {activity.length} Active days so far!</ActivityTitle>

                        {
                            loading ? <Skeleton count={6}/> : (
                                <>
                                    <HeatMap
                                        value={activity}
                                        weekLabels={['', 'Mon', '', 'Wed', '', 'Fri', '']}
                                        width={'100%'}
                                        startDate={startDate}
                                        endDate={tomorrow}
                                        panelColors={{
                                            0: '#383838',
                                            1: '#fb7185',
                                            2: '#f43f5e',
                                            4: '#e11d48',
                                            10: '#be123c',
                                            20: '#881337',
                                            // 30: '#000',
                                        }}
                                        style={{color: "#f1f1f1", '--rhm-rect-active': '#383838'}}
                                        rectRender={(props, data) => {
                                            if (!data.count) return <rect {...props} />;
                                            return (
                                                <rect {...props} data-tooltip-id="my-tooltip" data-tooltip-content={`${data.date} - ${data.count || 0} activities`}/>
                                            );
                                        }}
                                    />
                                    <Tooltip id="my-tooltip"/>
                                </>
                            )
                        }

                    </ActivitySection>

                    <BottomSection>
                        <InformationSection>
                            Coming Soon...
                        </InformationSection>
                        
                    </BottomSection>
                </RightSection>
            </ProfileBody>
        </DialogContent>
    );
}

export default MyProfile;