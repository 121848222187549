import styled from "styled-components";
import LoadingIcon from "../assets/fractal_tree.gif";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import useUserDetails from "api/user/useUserDetails";


const Container = styled(motion.div)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: ${(props) => (props.isLanding ? "auto" : "100vh")};
    margin: 0;
    padding: 0;
`;

function LoadingScreen({ isLanding = false }) {
    const [gifSrc, setGifSrc] = useState(`${LoadingIcon}?${Date.now()}`);

    useEffect(() => {
        // Update the gif source with a new timestamp to force reload
        setGifSrc(`${LoadingIcon}?${Date.now()}`);
    }, []);

    useUserDetails();

    return (
        <Container
            isLanding={isLanding} // Pass the prop to the styled component
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <img
                src={gifSrc}
                alt="loading..."
            />
        </Container>
    );
}

export default LoadingScreen;