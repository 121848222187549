import React, { useEffect, useRef, useState } from 'react';
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "../../assets/searchIcon.svg";
import { useLocation } from "react-router-dom";


const Container = styled.div`
    background-color: rgba( 25, 25, 28, 1 );
    border: 1px solid ${props => props.isFocused ? '#D9D9D9' : '#FFFFFF50'};
    border-radius: 12px;
    padding-left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color 0.3s ease; /* Smooth transition */
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 0px 12px 0px 12px;
  border: none;
  font-size: 15px;
  background-color: rgba( 25, 25, 28, 1 );
  color: white;
  margin-right: 4px;
  user-select: none;

  &:focus {
    outline: none;
  }
`;

function SearchBar({ onSearch }) {
    const inputRef = useRef(null);
    const [query, setQuery] = useState('');
    const [isFocused, setIsFocused] = useState(false);
    const location = useLocation();

    const handleChange = (event) => {
        const value = event.target.value;
        setQuery(value);
        onSearch(value); // Notify the parent component about the search query
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    useEffect(() => {
        if (!location.pathname.startsWith('/course') && !location.pathname.startsWith('/exam') && !location.pathname.startsWith('/premium')) {
            const handleKeyDown = (event) => {
                if (event.key === '/') {
                    event.preventDefault();
                    inputRef.current.focus();
                }
            };
        
            const handleEnterPress = (event) => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    inputRef.current.blur();
                }
            };
        
            window.addEventListener('keydown', handleKeyDown);
            inputRef.current.addEventListener('keydown', handleEnterPress);
        
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
                if (inputRef.current) {
                    inputRef.current.removeEventListener('keydown', handleEnterPress);
                }
            };
        }
    }, []);

    if (location.pathname.startsWith('/course') || location.pathname.startsWith('/exam') || location.pathname.startsWith('/premium')) {
        return null;
    }

    return (
        <Container isFocused={isFocused}>
            <SearchIcon width={25}/>
            
            <SearchInput 
                ref={inputRef} 
                placeholder='Press "/" to Search' 
                type='text' 
                value={query} 
                onChange={handleChange} 
                onFocus={handleFocus} 
                onBlur={handleBlur}
            />
        </Container>
    );
}

export default SearchBar;