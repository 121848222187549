import { useState, useEffect } from 'react';

export function useSingleMaterial(id) {
  const [material, setMaterial] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchCourse() {
      try {
        const response = await fetch(`http://${process.env.REACT_APP_API}/materials/${id}`);
        const data = await response.json();
        setMaterial(data.content);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }

    fetchCourse();
  }, [id]);

  return { material, loading, error };
}