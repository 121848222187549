import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import {
    InputOTP,
    InputOTPGroup,
    InputOTPSeparator,
    InputOTPSlot,
} from "../../../shadcn/ui/input-otp";


const Container = styled.div`
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
`;

function RacinesTypeII({ response, setResponse, answerLen }) {
    const otpRef = useRef(null);

    useEffect(() => {
        if (otpRef.current) {
            otpRef.current.focus();
        }
    }, []);

    return (
        <Container>
            {answerLen === 2 ? (
                <InputOTP ref={otpRef} maxLength={2} value={response} onChange={(v) => setResponse(v)} style={{caretColor: "white"}}>
                    <InputOTPGroup style={{caretColor: "white"}}>
                        <InputOTPSlot index={0} style={{padding: "10px", fontSize: "20px"}}/>
                    </InputOTPGroup>
                        <h1 style={{paddingLeft: "10px", paddingRight: "10px", fontSize: "22px"}}>√</h1>
                    <InputOTPGroup>
                        <InputOTPSlot index={1} style={{padding: "10px", fontSize: "20px"}}/>
                    </InputOTPGroup>
                </InputOTP>
            ) :
            (
                <InputOTP maxLength={3} value={response} onChange={(v) => setResponse(v)} style={{caretColor: "white"}}>
                    <InputOTPGroup style={{caretColor: "white"}}>
                        <InputOTPSlot index={0} style={{padding: "10px", fontSize: "20px"}}/>
                        <InputOTPSlot index={1} style={{padding: "10px", fontSize: "20px"}}/>
                    </InputOTPGroup>
                        <h1 style={{paddingLeft: "10px", paddingRight: "10px", fontSize: "22px"}}>√</h1>
                    <InputOTPGroup>
                        <InputOTPSlot index={2} style={{padding: "10px", fontSize: "20px"}}/>
                        <InputOTPSlot index={3} style={{padding: "10px", fontSize: "20px"}}/>
                    </InputOTPGroup>
                </InputOTP>
            )
        }
        </Container>
    );
}

export default RacinesTypeII;