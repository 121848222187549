import { useState, useEffect } from 'react';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';


export const useLessonExercises = (lessonId) => {
    const [exercises, setExercises] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const authHeader = useAuthHeader();

    useEffect(() => {
        const fetchExercises = async () => {
            try {
                setError(null);
                
                const response = await fetch(`http://${process.env.REACT_APP_API}/lesson/${lessonId}/exercises`, {
                    method: 'GET',
                    headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${authHeader}`, 
                    },
                    credentials: 'include', // Send cookies with the request
                });

                if (!response.ok) {
                    if (response.status === 401) {
                        console.error('Unauthorized! Please log in again.');
                    }
                    setError("401")

                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();

                if (!data.error) {
                    setExercises(data.content);
                } else {
                    setError(data.errorMessage);
                }
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchExercises();
    }, [lessonId]);

    return { exercises, loading, error };
};