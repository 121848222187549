import styled from "styled-components";
import {
    InputOTP,
    InputOTPGroup,
    InputOTPSeparator,
    InputOTPSlot,
} from "../../../shadcn/ui/input-otp";


const Container = styled.div`
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px;
`;

function RacinesTypeIII({ response, setResponse }) {
    return (
        <Container>
            <InputOTP maxLength={4} value={response} onChange={(v) => setResponse(v)} style={{caretColor: "white"}}>
                <InputOTPGroup style={{caretColor: "white"}}>
                    <InputOTPSlot index={0} style={{padding: "10px", fontSize: "20px"}}/>
                    <InputOTPSlot index={1} style={{padding: "10px", fontSize: "20px"}}/>
                </InputOTPGroup>
                    <h1 style={{paddingLeft: "10px", paddingRight: "10px", fontSize: "22px"}}>√</h1>
                <InputOTPGroup>
                    <InputOTPSlot index={2} style={{padding: "10px", fontSize: "20px"}}/>
                    <InputOTPSlot index={3} style={{padding: "10px", fontSize: "20px"}}/>
                </InputOTPGroup>
            </InputOTP>
        </Container>
    );
}

export default RacinesTypeIII;