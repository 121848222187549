import { motion } from "framer-motion";


const ContentTransition = ({ children }) => {
    return (
        <motion.div
            initial={{ y: +35, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 0, opacity: 1 }}
            style={{ width: "100%", height: "100%" }}
            transition={{ duration: 0.5 }}
        >
            {children}
        </motion.div>
    );
};

export default ContentTransition;