import { Routes, Route } from 'react-router-dom';
import './App.css';
import HomeScreen from './scenes/HomeScreen';
import CourseDetailScreen from './scenes/CourseMainScreen';
import NavBar from './components/global/NavBar';
import LoginScreen from 'scenes/LoginScreen';
import RegisterScreen from 'scenes/RegisterScreen';
import { Toaster } from './components/shadcn/ui/toaster';
import { AnimatePresence } from 'framer-motion';
import RequireAuth from '@auth-kit/react-router/RequireAuth';
import { useEffect, useState } from 'react';
import LoadingPage from "./scenes/LoadingScreen";
import LandingPageScreen from 'scenes/LandingPageScreen';
import ProgressBar from 'components/global/ProgressBar';
import ExamScreen from 'scenes/ExamScreen';
import OnboardingScreen from 'scenes/OnboardingScreen';
import LoadingIcon from "assets/fractal_tree.gif";
import NotFoundScreen from 'scenes/NotFoundScreen';


function App() {
  const [screenLoading, setScreenLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);


  // TODO: Make mobile design
  useEffect(() => {
    const handleResize = () => {
      const isMobileDevice = window.matchMedia("only screen and (max-width: 1000px)").matches;
      setIsMobile(isMobileDevice);
    };

    handleResize(); // Check on load
    window.addEventListener("resize", handleResize); // Add event listener

    return () => window.removeEventListener("resize", handleResize); // Clean up on unmount
  }, []);

  useEffect(() => {
    setTimeout(() => setScreenLoading(false), 4000);
  }, []);

  useEffect(() => {
    setTimeout(() => setScreenLoading(false), 4000)
  }, [])

  if (isMobile) {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "100vh", marginTop: "-15vh" }}>\
        <img src={LoadingIcon} alt="loading..." style={{ scale: "1", padding: "0", margin: "0" }}/>
        <h1 style={{ color: "white", textAlign: "center", paddingBottom: "12px" }}>Sorry, Mobile not supported yet 🥲</h1>
        <h1 style={{ color: "white", textAlign: "center" }}>Coming Soon... 🚀</h1>
      </div>
    )
  }

  if (screenLoading) {
    return <LoadingPage/>
  }

  return (
    <div className="App">
      <AnimatePresence mode='wait'>
        <ProgressBar/>
        <NavBar/>
        
        <Routes>
          <Route path="/" element={
            <RequireAuth fallbackPath={"/home"}>
              <HomeScreen />
            </RequireAuth>
          } />
          <Route path="/course/:id/*" element={
            <RequireAuth fallbackPath={"/home"}>
              <CourseDetailScreen />
            </RequireAuth>
          } />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/register" element={<RegisterScreen />} />
          <Route path="/home" element={<LandingPageScreen />} />
          <Route path="/onboarding" element={<OnboardingScreen />} />
          <Route path="/exam" element={<ExamScreen />} />
          <Route path='*' element={<NotFoundScreen/>} />
          {/* <Route path="/premium" element={
            <RequireAuth fallbackPath={"/login"}>
              <PremiumScreen />
            </RequireAuth>
          } /> */}
        </Routes>

        <Toaster/>
      </AnimatePresence>
    </div>
  );
}

export default App;