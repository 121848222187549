import React from 'react';
import styled from 'styled-components';
import { useCourseLessons } from '../../api/courses/useCourseLessons';
import { useCourseExercises } from '../../api/courses/useCourseExercises';
import { useNavigate } from 'react-router-dom';
import { useSelectedItem } from '../../utilities/navSelectedContent';
import { useCourseMaterials } from '../../api/courses/useCourseMaterial';
import { motion } from 'framer-motion';
import useUserStore from 'stores/userUserStore';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'


const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #222222;
    color: white;
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #676767;
    width: 400px;
    height: 400px;
    user-select: none;
    position: sticky;
    top: 100px;
    overflow-y: auto;
`;

const ScrollableSection = styled.div`
    flex-grow: 1;
    overflow-y: auto;
`;

const Section = styled.div`
    margin-bottom: 16px;
`;

const SectionTitle = styled.h3`
    font-size: 1.1em;
    margin-bottom: 8px;
    border-bottom: 1px solid #676767;
    padding-bottom: 4px;
    font-weight: 500;
`;

const LessonItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: ${({ isSelected }) => (isSelected ? '#505050' : 'transparent')};
    scroll-snap-align: start; /* Snap item to the top */

    &:hover {
        background-color: #505050;
    }
`;

const LessonName = styled.span`
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 400;
`;

const LessonIcon = styled.span`
    margin-right: 8px;
`;

const LessonTime = styled.span`
    font-size: 0.8em;
    color: #b3b3b3;
`;

function CourseOutline({courseId, title, r, g, b}) {
    const navigate = useNavigate();
    const { lessons, lessonLoading, lessonError } = useCourseLessons(courseId);
    const { exercises, loading: exercisesLoading, error: exercisesError } = useCourseExercises(courseId);
    const { materials, materialsLoading, materialsError } = useCourseMaterials(courseId);
    const { selectedItem, setSelectedItem } = useSelectedItem();
    const user = useUserStore((state) => state.user);

    if (lessonLoading || exercisesLoading || materialsLoading) return <></>;

    if (lessonError) return <p>Error: {lessonError}</p>;
    if (exercisesError) return <p>Error: {exercisesError}</p>;
    if (materialsError) return <p>Error: {materialsError}</p>;

    const handleLessonClick = (lessonId) => {
        setSelectedItem(lessonId);
        navigate(`/course/${courseId}/lesson/${lessonId}`, { state: { title, r, g, b } });
    };

    const handleExerciseClick = (exerciseId) => {
        setSelectedItem(exerciseId);
        navigate(`/course/${courseId}/exercise/${exerciseId}`, { state: { title, r, g, b } });
    };

    const handleMaterialClick = (materialId) => {
        setSelectedItem(materialId);
        navigate(`/course/${courseId}/material/${materialId}`, { state: { title, r, g, b } });
    };

    return (
        <Container>
            <motion.div
                initial={{y: 75}}
                animate={{y: 0}}
                exit={{y: 0}}
                style={{width: "100%", height: "100%"}}
                transition={{duration: 0.5}}
            >
                <ScrollableSection>
                    <Section>
                        {
                            lessons == null ? <Skeleton/> : (
                                <>
                                    <SectionTitle>Lessons</SectionTitle>
                                    {lessons.map((lesson, index) => (
                                        <LessonItem key={lesson.id} onClick={() => handleLessonClick(lesson.id)} isSelected={selectedItem === lesson.id}>
                                            <LessonName>
                                                <LessonIcon>{user?.isPremium ? '⚪' : (lesson.isForPremium ? '🔒' : '⚪')}</LessonIcon>
                                                {lesson.title}
                                            </LessonName>
                                            <LessonTime>{lesson.videoDuration}</LessonTime>
                                        </LessonItem>
                                    ))}
                                </>
                            )
                        }
                       
                    </Section>

                    <Section>
                        <SectionTitle>Exercises</SectionTitle>
                        {exercises.map((exercise) => (
                            <LessonItem key={exercise.id} onClick={() => handleExerciseClick(exercise.id)} isSelected={selectedItem === exercise.id}>
                                <LessonName>
                                    <LessonIcon>{user?.isPremium ? '⚪' : (exercise.isForPremium ? '🔒' : '⚪')}</LessonIcon>
                                    {exercise.title}
                                </LessonName>
                            </LessonItem>
                        ))}
                    </Section>

                    <Section>
                        <SectionTitle>Materials</SectionTitle>
                        {materials.map((material) => (
                            <LessonItem key={material.id} onClick={() => handleMaterialClick(material.id)} isSelected={selectedItem === material.id}>
                                <LessonName>{material.id}</LessonName>
                            </LessonItem>
                        ))}
                    </Section>

                </ScrollableSection>
            </motion.div>
        </Container>
    );
}

export default CourseOutline;