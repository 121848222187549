export function getCurrentDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    
    const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const month = months[today.getMonth()];

    const year = today.getFullYear();

    return `${day} ${month} ${year}`;
}

export function formatDate(dateStr) {
    const date = new Date(dateStr);

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
}

export function calculateStartDate() {
    const currentDate = new Date();
    const screenWidth = window.innerWidth;

    console.log(screenWidth);

    const maxMonthsEarlier = 10;
    const monthsEarlier = Math.min(screenWidth / 100, maxMonthsEarlier);

    const startDate = new Date(currentDate);
    startDate.setMonth(startDate.getMonth() - monthsEarlier);

    const minimumStartDate = new Date(currentDate);
    minimumStartDate.setMonth(minimumStartDate.getMonth() - 10);

    return startDate < minimumStartDate ? minimumStartDate : startDate;
}