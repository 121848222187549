import { useState, useEffect } from 'react';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';
import useAuthUser from 'react-auth-kit/hooks/useAuthUser';

const useUserActivity = () => {
    const [activity, setActivity] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const authHeader = useAuthHeader();
    const authUser = useAuthUser();

    useEffect(() => {
        const getActivity = async () => {
            const response = await fetch(`http://${process.env.REACT_APP_API}/user/${authUser.username}/activity`, {
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `${authHeader}`, 
                },
                credentials: 'include',
            });

            if (!response.ok) {
                // Handle specific HTTP status codes if necessary
                if (response.status === 401) {
                  console.error('Unauthorized! Please log in again.');
                  // Optionally, redirect to login page or clear session
                }
                throw new Error(`HTTP error! status: ${response.status}`);
              }
          
              const result = await response.json();

            if (result.error) {
                setError(result.errorMessage);
            } else {
                setActivity(result.content);
            }
            
            setLoading(false);
        };

        getActivity();
    }, []);

    return { activity, error, loading };
};

export default useUserActivity;