import styled from "styled-components";


const ExerciseReportBody = styled.div`
    background-color: #1e1e1e;
    color: white;
    padding: 20px;
    border-radius: 8px;
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Metric = styled.div`
    font-size: 20px;
    font-weight: bold;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: ${({ value }) => getColor(value)};
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
`;

const getColor = (value) => {
    const colors = [
        '#ff0000', // 0
        '#ff4000', // 1
        '#ff8000', // 2
        '#ffbf00', // 3
        '#ffff00', // 4
        '#bfff00', // 5
        '#80ff00', // 6
        '#40ff00', // 7
        '#00ff00', // 8
        '#00ff40', // 9
        '#00ff80'  // 10
    ];
    return colors[Math.min(Math.max(value, 0), 10)];
};

function ExerciseReport({ report }) {
    return (
        <ExerciseReportBody>
            <p>Exercise Report</p>
            <Metric value={report.performanceMetric}>{report.performanceMetric}</Metric>
        </ExerciseReportBody>
    );
};

export default ExerciseReport;