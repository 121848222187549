import { useState, useEffect } from 'react';
import useAuthHeader from 'react-auth-kit/hooks/useAuthHeader';


export function useSingleExercise(id) {
  const [exercise, setExercise] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const authHeader = useAuthHeader();

  useEffect(() => {
    async function fetchCourse() {
      try {
        setError(null);
        const response = await fetch(`http://${process.env.REACT_APP_API}/exercise/${id}`, {
          method: 'GET',
          headers: {
          'Content-Type': 'application/json',
          'Authorization': `${authHeader}`, 
          },
          credentials: 'include', // Send cookies with the request
        });

        if (!response.ok) {
          if (response.status === 401) {
              console.error('Unauthorized! Please log in again.');
          }

          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!data.error) {
          setExercise(data.content);
        } else {
            setError(data.errorMessage);
        }
      } catch (err) {
        setError(err.message);
      } finally {
          setLoading(false);
      }
    }

    fetchCourse();
  }, [id]);

  return { exercise, loading, error };
}