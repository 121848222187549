import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    padding: 0;
    color: white;
    margin-top: -20vh;
`;

const Title = styled.h1`
    font-size: 24px;
    margin-bottom: 16px;
    font-weight: 700;
`;

function NotFoundScreen() {
    return (
        <Container>
            <Title>404 Page Not Found 😢</Title>
        </Container>
    );
}

export default NotFoundScreen;